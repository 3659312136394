import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectSpeakers, insertSpeaker } from '../features/speaker/speakerSlice'
import axios from 'axios'
import { Link } from 'react-router-dom'
import loading_img from '../assets/load_blink_2.gif'
import { CirclePackChart } from '@carbon/charts-react'
import '@carbon/charts/styles.css'
import { PersonShort } from '../models/person'
import { Transition, TransitionGroup } from 'react-transition-group'
import { LectureSpeakers } from '../components/layout/LectureSpeakers'
import { LectureList } from '../components/layout/LectureList'
import { ScrollToTop } from '../scripts/ui'
import { fetcher, fetchData } from '../scripts/net'
import useSWR from 'swr'
// Models

// Options
let graph_options = {
  title: '',
  height: '400px',
  toolbar: { enabled: false },
  legend: { enabled: false },
  canvasZoom: { enabled: true },
  showtotal: true,
  resizable: true,
  tooltip: {
    enabled: true,
    truncation: { threshold: 200 },
    showTotal: true,
    totalLabel: 'Lectures',
  },
}

const duration = 750 // 3/4 sec

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

export default function LecturesHome() {
  // API CALLS
  //
  // API Stats

  const useStats = () => {
    const url = `${process.env.REACT_APP_API_ROUTE}/lectures/stats/`
    const { data, error } = useSWR(url, fetcher)
    return {
      stats: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  function StatsContent() {
    const { stats, isLoading, isError } = useStats()
    if (isLoading)
      return (
        <div>
          <img src={loading_img} alt="LOADING" />
        </div>
      )
    if (isError) return <div>ERROR</div>
    return (
      <ul>
        <li>{stats.lecture_count}</li>
        <li>{stats.guest_count}</li>
        <li>{stats.school_count}</li>
      </ul>
    )
  }

  const useSchools = () => {
    let url = `${process.env.REACT_APP_API_ROUTE}/schools/`
    const { data, error } = useSWR(url, fetcher)
    return {
      orgs: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  // function AltGraph() {
  //   const { orgs, isLoading, isError } = useSchools()

  //   if (isLoading)
  //     return (
  //       <div>
  //         <img src={loading_img} alt="LOADING" />
  //       </div>
  //     )
  //   if (isError) return <div>ERROR</div>

  //   let hostGraph = []
  //   orgs.map((host) =>
  //     hostGraph.push({ name: host.name, value: host.lecture_count })
  //   )

  //   return (
  //     <div>
  //       <CirclePackChart
  //         data={hostGraph}
  //         options={graph_options}
  //       ></CirclePackChart>
  //     </div>
  //   )
  // }

  // API CALL -> Schools
  const sLoc = `${process.env.REACT_APP_API_ROUTE}/schools/`
  const [graph_data, setGraphData] = useState([])
  const [schools, setSchools] = useState([])
  useEffect(() => {
    axios
      .get(sLoc)
      .then((res) => {
        setGraphData(
          res.data.map((school, i) => ({
            name: school.name,
            value: school.lecture_count,
          }))
        )
        setSchools(res.data)
      })
      .catch((e) => {
        Error(e)
      })
  }, [sLoc])

  //
  // Content Lecture Hosts
  const [slistClass, setSListClass] = useState('hide')
  const [lectureHosts, setLectureHosts] = useState([])
  useEffect(() => {
    if (schools !== null) {
      let hosts = schools.map((s, i) => (
        <Link className="" key={i} to={{ pathname: `schools/${s.slug}` }}>
          <li>
            {s.name} - {s.lecture_count}
          </li>
        </Link>
      ))
      setLectureHosts(hosts)
      const set_sclist = setInterval(() => setSListClass('show'), 100)

      return () => clearInterval(set_sclist)
    }
  }, [schools])

  const usePeople = () => {
    let url = `${process.env.REACT_APP_API_ROUTE}/people/?sort=alpha&limit=100`
    const { data, error } = useSWR(url, fetcher)
    return {
      people: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  const LectureSpeakers = () => {
    const { people, isLoading, isError } = usePeople()
    if (isLoading) return <div>Loading</div>
    if (isError) return <div>ERROR</div>
    if (people !== null) {
      return people.map((person, i) => (
        <Link key={i} to={{ pathname: `/people/${person.slug}` }}>
          <li>{person.name}</li>
        </Link>
      ))
    }
  }

  //
  // Content Lecture List
  const [lectureListClass, setLectureListClass] = useState('hide')
  const [lectureList, setLectureList] = useState()
  useMemo(() => {
    const fetch = async () => {
      const result = await (
        <LectureList
          url={`${process.env.REACT_APP_API_ROUTE}/lectures/?sort=date&dated=true&limit=100`}
        />
      )
      setLectureList(result)
      setTimeout(() => setLectureListClass('show'), 50)
    }
    fetch()
  }, [])
  //
  // Content Title
  const [titleContent, setTitleContent] = useState()
  const [inProp, setInProp] = useState(false)
  useEffect(() => {
    const title_content = (
      <>
        <div className="columns lecture-head">
          <div className="columns lecture stats is-variable is-7 mb-2">
            <div className="column is-12">
              <p>
                Our Archive is a constantly growing source of lectures from the
                field of architecture and other foci distributed around the web.{' '}
                <br />
                <br /> We are working to find more ways to, view, digest,
                interpret, understand, and relate to the thinking and ideas
                expressed within these disparate jewels of thought.
                <br />
                <br /> Enjoy.
              </p>
            </div>
          </div>
        </div>
      </>
    )
    setTitleContent(title_content)
    setInProp(true)
  }, [])

  return (
    <>
      <div className="columns is-gapless">
        <div className="column is-2">
          <h5 className="pt-5 is-size-6">Speakers</h5>
          <ul className={`lecture people-guest-list mr-6`}>
            <LectureSpeakers />
          </ul>
        </div>
        <div className="column is-7">
          <div className="columns lecture-head">
            <div className="column is-6">
              <Transition in={inProp} appear={true} timeout={{ enter: 100 }}>
                {(state) => (
                  <div
                    className="titleContent"
                    style={{
                      defaultStyle,
                      ...transitionStyles[state],
                    }}
                  >
                    {titleContent}
                  </div>
                )}
              </Transition>
            </div>
            <div className="column is-6">
              <CirclePackChart
                data={graph_data}
                options={graph_options}
              ></CirclePackChart>
            </div>
          </div>
          <div className={`columns lecture table ${lectureListClass}`}>
            {lectureList}
          </div>
          <div></div>
        </div>
        <div className={`column is-3 pl-4 ${slistClass}`}>
          <div className={`lecture-host-list ${slistClass}`}>
            {/* <StatsContent /> */}
            <h5 className="pt-5 is-size-6">Schools</h5>
            <ul>{lectureHosts}</ul>
          </div>
        </div>
      </div>
      <ScrollToTop speed={100} />
    </>
  )
}
