import { useState, useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'

import { selectSpeakers, insertSpeaker } from '../features/speaker/speakerSlice'

// import scroll_to_top from '../scripts/ui'
import loading_img from '../assets/load_blink_2.gif'
import axios from 'axios'
import { fetcher } from '../scripts/net'
import useSWR from 'swr'

export default function PeopleHome() {
  const { url } = useRouteMatch()
  const peeps = useSelector(selectSpeakers)
  const dispatch = useDispatch()

  const usePeople = () => {
    const url = `${process.env.REACT_APP_API_ROUTE}/people/?sort=alpha&thresh=3`
    const { data, error } = useSWR(url, fetcher)
    return {
      people: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  const valueSort = (list, sortValue, dir) => {
    let sorted = list.sort((a, b) => {
      if (a[sortValue] < b[sortValue]) return -1
      if (a[sortValue] > b[sortValue]) return 1
      return 0
    })
    return !dir ? sorted : sorted.reverse()
  }

  const byQuery = (query) => (item) =>
    !query || item.name.toLowerCase().includes(query.toLowerCase())

  // Search Component
  const Search = ({ query, handleQuery }) => (
    <div className="listing-search">
      <input type="text" value={query} onChange={handleQuery} />
      <button className="search-btn" onClick={handleQuery} value="">
        Reset
      </button>
    </div>
  )

  // List Component
  const List = ({ query, people }) => {
    return people.filter(byQuery(query)).map((person, i) =>
      person.lecture_count > 1 ? ( // iterate all of the people with more than 1 lecture
        peeps.includes(person.name) ? ( // check to see if they are already selected
          <li key={i} className="p-1 selected">
            <div className="selected">{person.name}</div>
          </li>
        ) : (
          <li key={i} className="p-1 active">
            <Link to={`${url}/${person.slug}`}>{person.name}</Link>
          </li>
        )
      ) : (
        <></>
      )
    )
  }

  // NavBar Component
  const NavBar = (props) => {
    const {
      sorted: [sorted, setSorted],
    } = { sorted: useState(), ...props.sorted }

    return (
      <nav>
        <ul>
          {props.values.map((value, i) => (
            <li
              className={sorted.index !== i ? 'is-off' : 'is-active'}
              key={i}
              onMouseDown={() =>
                setSorted({
                  ...sorted,
                  index: i,
                  sort: value.sort,
                  dir: sorted.dir ? 0 : 1,
                })
              }
            >
              {value.title}
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  // Primary Component
  function PeopleListing() {
    let values = [
      { sort: 'name', title: 'Name' },
      { sort: 'lastname', title: 'Last Name' },
      { sort: 'host_count', title: 'Host Count' },
      { sort: 'lecture_count', title: 'Lectures Count' },
    ]

    const [query, setQuery] = useState('')
    const [sorted, setSorted] = useState({
      index: 0,
      sort: '',
      dir: 0,
    })

    const { people, isLoading, isError } = usePeople()
    if (isLoading) return <img src={loading_img} alt="LOADING" />
    if (isError) return <div>ERROR</div>

    const handleQuery = (e) => {
      setQuery(e.target.value)
    }

    console.log(sorted)

    if (sorted !== null) {
      let vals = values.map((value) => value.sort)
      let mapped = vals.includes(sorted.sort)
        ? valueSort([...people], sorted.sort, sorted.dir)
        : valueSort([...people], values[0], sorted.dir)

      return (
        <div className="listing-sorted">
          <div className="listing-navbar">
            <NavBar sorted={{ sorted: [sorted, setSorted] }} values={values} />
            <Search query={query} handleQuery={handleQuery} />
            {/* Search List:
            </Search> */}
          </div>
          <div className="listing-list">
            <List query={query} people={mapped} />
          </div>
        </div>
      )
    }
  }

  const Speakers = (props) => {
    return props.peeps && props.peeps.length >= 1 ? (
      <>
        <h6>Visited Speakers: </h6>
        <ul>
          {props.peeps.map((person, i) => (
            <li>
              <Link to={`${url}/${person.toLowerCase().replaceAll(' ', '-')}`}>
                {person}
              </Link>
            </li>
          ))}
        </ul>
      </>
    ) : (
      <></>
    )
  }

  return (
    <>
      <div className="columns is-size-1">
        People includes architects, thinkers, speakers, and those not covered
        who have engaged in the lecture discourse.
      </div>
      <div className="columns">
        <div className="column is-12 p-0">
          <div className="people-list visited">
            <Speakers peeps={peeps} />
          </div>

          <div className="people-list listing">
            <PeopleListing />
          </div>
        </div>
      </div>
    </>
  )
}
