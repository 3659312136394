import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import loading_img from '../assets/load_blink_2.gif'
import { Link } from 'react-router-dom'
import { VideoScreen } from '../components/media/videoContent'
import { DonutChart, AlluvialChart } from '@carbon/charts-react'
import PersonHost from '../components/person/personHost'
import { ScrollToTop } from '../scripts/ui'
import { fetcher } from '../scripts/net'
import useSWR from 'swr'
import parse from 'html-react-parser'
import { selectSpeakers, insertSpeaker } from '../features/speaker/speakerSlice'
import 'leaflet/dist/leaflet.css'

import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  CircleMarker,
  ZoomControl,
} from 'react-leaflet'

const PeopleDetail = () => {
  const { slug } = useParams()
  const peeps = useSelector(selectSpeakers)
  const dispatch = useDispatch()

  const usePerson = (slug) => {
    const loc = `${process.env.REACT_APP_API_ROUTE}/people/${slug}/`
    const { data, error } = useSWR(loc, fetcher)
    return {
      person: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  const usePersonLectures = (slug) => {
    const loc = `${process.env.REACT_APP_API_ROUTE}/people/${slug}/lectures/`
    const { data, error } = useSWR(loc, fetcher)
    return {
      lectures: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  const useAltPerson = (slug) => {
    let url = `${process.env.REACT_APP_API_ROUTE}/people/${slug}/`
    return useSWR(url, fetcher)
  }

  function Person() {
    const { person } = usePerson(slug)
    if (!person)
      return (
        <div>
          <img src={loading_img} alt="LOADING" />
        </div>
      )
    if (!peeps.includes(person.name)) dispatch(insertSpeaker(person.name))
    return (
      <div>
        <div>
          {person.hosts.map((host, i) => (
            <div key={i}>
              <Link to={`/schools/${host.slug}`}>{host.name}</Link>
            </div>
          ))}
        </div>
      </div>
    )
  }

  function SpeakerMap() {
    const { person } = usePerson(slug)
    const height = '275px'
    const width = '100%'
    if (person.hosts !== undefined && person.hosts[0].location) {
      const bounds = person.hosts.map((host) => [
        host.location.lat,
        host.location.lon,
      ])
      const points = person.hosts.map((host, i) => (
        <CircleMarker
          key={i}
          center={{
            lat: host.location.lat,
            lng: host.location.lon,
          }}
          radius={5}
          weight={1}
          stroke={false}
          fill={true}
          fillOpacity={0.5}
          color="#222222"
        >
          <Popup>
            {host.name} - {host.appearances}
          </Popup>
        </CircleMarker>
      ))
      const map = (
        <MapContainer
          zoomControl={false}
          bounds={bounds}
          boundsOptions={{ padding: [10, 10] }}
          extend={bounds}
          style={{ height: height, width: width }}
        >
          <TileLayer
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
          />
          <ZoomControl position="bottomright" zoomInText="+" zoomOutText="-" />
          {points}
        </MapContainer>
      )

      return map
    } else {
      return <div>ERROR</div>
    }
  }

  const optionsDonut = {
    title: '',
    resizable: false,
    legend: { enabled: false },
    tooltip: {},
    //   alignment: 'center',
    //   position: 'left',
    // },
    donut: {
      center: {
        label: 'Lectures',
      },
      // alignment: center,
    },
    height: '200px',
  }

  function GraphDonut() {
    const { data } = useAltPerson(slug)
    let graphData = []
    data.hosts.map((host) =>
      graphData.push({ group: host.shortname, value: host.appearances })
    )
    return (
      <div>
        <DonutChart data={graphData} options={optionsDonut}></DonutChart>
      </div>
    )
  }

  function GraphAlluvial() {
    const { data } = useAltPerson(slug)
    const options = {
      // title: 'Alluvial (gradient)',
      tooltip: {},

      // style: 'af',
      resizable: true,
      toolbar: {
        enabled: true,
        // controls: { clickFunction: setTester('Hello') },
      },
      legend: { enabled: true, position: 'bottom' },

      alluvial: {
        nodePadding: 0,
        nodes: [
          {
            name: 'Lectures',
            category: 'Pattern',
          },
        ],
      },
      height: '200px',
      width: '400px',
    }
    let graphData = []
    data.hosts.map((host) =>
      graphData.push({
        source: 'Lectures',
        target: host.shortname,
        value: host.appearances,
      })
    )

    data.hosts.map((host) =>
      options.alluvial.nodes.push({
        name: host.shortname,
        category: host.appearances,
      })
    )
    return (
      <div className="chart-alluvial">
        <AlluvialChart data={graphData} options={options}></AlluvialChart>
      </div>
    )
  }

  const PersonContent = () => {
    const { person, isLoading, isError } = usePerson(slug)

    if (isLoading)
      return (
        <div>
          <img src={loading_img} alt="LOADING" />
        </div>
      )
    if (isError) return <div>ERROR</div>

    return (
      <>
        <div className="columns ">
          <h1 className="is-size-1">{person.name}</h1>
        </div>
        <div className="columns">
          <div className="column is-4 p-4">
            <Person />
          </div>
          <div className="column is-8">
            <div className="columns">
              <div className=" column p-0 is-4">
                <GraphDonut />
              </div>
              <div className=" column is-2"></div>
              <div className=" column p-0 is-6">
                <SpeakerMap />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="columns">
          <div className=" column is-4">
            <GraphDonut />
          </div>
          <div className=" column is-4">
            <GraphAlluvial />
          </div>
          <div className=" column is-4">
            <GraphAlluvial />
          </div>
        </div> */}
      </>
    )
  }

  const LecturesContent = () => {
    const { lectures, isLoading, isError } = usePersonLectures(slug)
    if (isLoading)
      return (
        <div>
          <img src={loading_img} alt="LOADING" />
        </div>
      )
    if (isError) return <div>ERROR</div>

    let lectureList = lectures.map((lec, i) => (
      <li key={i} className="p-3 mb-6">
        <div className="list-item">
          <div className="video">
            <VideoScreen
              embed_url={lec.embed_url}
              title={lec.title}
              ratio={'ar43'}
            />
          </div>

          <div className="video-desc pt-1 mb-2 is-size-7">
            {lec.lecture_date} |{' '}
            <Link to={`/schools/${lec.host_slug}`}>{lec.host_name}</Link>
            {/* <div>{parse(lec.lecture_desc)}</div> */}
          </div>
        </div>
      </li>
    ))

    let content = <ul className="people-lecture-list">{lectureList}</ul>

    return content
  }

  return (
    <>
      <div className="section-billboard columns person">
        <div className="column">
          <PersonContent />
        </div>
      </div>
      <div className="columns lectures-content">
        <div className="column">
          <LecturesContent />
        </div>
      </div>
      <div>
        <div
          onClick={() =>
            document
              .querySelector('#root')
              .scrollIntoView({ behavior: 'smooth' })
          }
        >
          Go Up
        </div>
      </div>
      <ScrollToTop speed={100} />
    </>
  )
}

export default PeopleDetail
