import { useState, useEffect, useMemo, Component } from 'react'
import { Link, useRouteMatch, MemoryRouter } from 'react-router-dom'

import _ from 'lodash'

import useSWR from 'swr'

import L from 'leaflet'
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  CircleMarker,
  ZoomControl,
} from 'react-leaflet'

import { fetcher } from '../scripts/net'
import { ScrollToTop } from '../scripts/ui'

import loading_img from '../assets/load_blink_2.gif'
import { CirclePackChart } from '@carbon/charts-react'
import '@carbon/charts/styles.css'
import 'leaflet/dist/leaflet.css'

export default function SchoolsHome() {
  const { url } = useRouteMatch()

  const useStats = () => {
    const url = `${process.env.REACT_APP_API_ROUTE}/site/data/`
    const { data, error } = useSWR(url, fetcher)
    return {
      stats: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  const useSchools = () => {
    const url = `${process.env.REACT_APP_API_ROUTE}/schools/?all=True`
    const { data, error } = useSWR(url, fetcher)
    return {
      schools: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  const SchoolStats = () => {
    const { stats, isLoading, isError } = useStats()
    if (isLoading)
      return (
        <div>
          <img src={loading_img} alt="LOADING" />
        </div>
      )
    if (isError) return <div>ERROR</div>

    return (
      <ul>
        <li>
          <div>
            <h6>{stats.academy_count}</h6>
            <div>Schools</div>{' '}
          </div>
        </li>
        <li>
          <div>
            <h6>{stats.academy_active}</h6>
            <div>Schools Hosting Lectures</div>
          </div>
        </li>
      </ul>
    )
  }

  function SchoolMap(props) {
    const { schools, isLoading, isError } = useSchools()
    if (isLoading)
      return (
        <div>
          <img src={loading_img} alt="LOADING" />
        </div>
      )
    if (isError) return <div>ERROR</div>
    const height = props.height
    const width = '100%'

    const bounds = schools.map((school, i) => [
      school.location.lat,
      school.location.lon,
    ])

    // const MakePoints = (lat, lng, name, color, ) => {
    const MakePoints = (props) => {
      let popup
      props.popup ? (popup = props.popup) : (popup = props.name)
      return (
        <CircleMarker
          center={{
            lat: props.lat,
            lng: props.lng,
          }}
          radius={4}
          weight={1}
          stroke={false}
          fill={true}
          fillOpacity={0.5}
          color={props.color}
        >
          <Popup>{popup}</Popup>
        </CircleMarker>
      )
    }

    const emptyPoints = schools.map((school) => [
      school.lecture_count < 1
        ? MakePoints({
            lat: school.location.lat,
            lng: school.location.lon,
            name: school.name,
            color: '#808080',
            url: '',
          })
        : null,
    ])

    const activePoints = schools.map((school) => [
      school.lecture_count > 1
        ? MakePoints({
            lat: school.location.lat,
            lng: school.location.lon,
            name: school.name,
            color: '#e63599',
            popup: <a href={`${url}/${school.slug}`}>{school.name}</a>,
            // popup: <Link to={{ pathname:`${url}/${school.slug}/`}}>{school.name}</Link>,
          })
        : null,
    ])

    const map = (
      <MapContainer
        zoomControl={false}
        bounds={bounds}
        boundsOptions={{ padding: [20, 20] }}
        extend={bounds}
        style={{ height: height, width: width }}
      >
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}" />
        {emptyPoints}
        {activePoints}
      </MapContainer>
    )

    return map
  }

  // Value Sort
  const valueSort = (list, sortValue, dir) => {
    let sorted = list.sort((a, b) => {
      if (a[sortValue] < b[sortValue]) return -1
      if (a[sortValue] > b[sortValue]) return 1
      return 0
    })
    return !dir ? sorted : sorted.reverse()
  }

  // Query Filter
  const byQuery = (query) => (item) =>
    !query || item.name.toLowerCase().includes(query.toLowerCase())

  // Search Component
  const Search = ({ query, handleQuery }) => (
    <div className="listing-search">
      <input type="text" value={query} onChange={handleQuery} />
      <button className="search-btn" onClick={handleQuery} value="">
        Reset
      </button>
    </div>
  )

  // List Component
  const List = ({ query, schools }) => {
    return schools.filter(byQuery(query)).map((school, i) =>
      school.lecture_count > 1 ? (
        <li key={i} className="p-3 active">
          <Link to={`${url}/${school.slug}`}>
            {/* <div className="is-size-7">{school.lecture_count} - Lectures</div> */}
            {school.name}
          </Link>
        </li>
      ) : (
        <li key={school.name} className="p-3 passive">
          {school.name}
        </li>
      )
    )
  }

  // NavBar Component
  const NavBar = (props) => {
    const {
      sorted: [sorted, setSorted],
    } = { sorted: useState(), ...props.sorted }

    return (
      <nav>
        <ul>
          {props.values.map((value, i) => (
            <li
              className={sorted.index !== i ? 'is-off' : 'is-active'}
              key={i}
              onMouseDown={() =>
                setSorted({
                  ...sorted,
                  index: i,
                  sort: value.sort,
                  dir: sorted.dir ? 0 : 1,
                })
              }
            >
              {value.title}
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  // Primary Listing Component
  function SchoolListing() {
    let values = [
      { sort: 'name', title: 'Name' },
      { sort: 'lecture_count', title: 'Lecture Count' },
    ]

    const [query, setQuery] = useState('')
    const [sorted, setSorted] = useState({
      index: 0,
      sort: '',
      dir: 0,
    })

    const { schools, isLoading, isError } = useSchools()
    if (isLoading) return <img src={loading_img} alt="LOADING" />
    if (isError) return <div>ERROR</div>

    const handleQuery = (e) => {
      setQuery(e.target.value)
    }

    if (sorted !== null) {
      let vals = values.map((value) => value.sort)
      let mapped = vals.includes(sorted.sort)
        ? valueSort([...schools], sorted.sort, sorted.dir)
        : valueSort([...schools], values[0], sorted.dir)

      return (
        <div className="listing-sorted">
          <div className="listing-navbar">
            <NavBar sorted={{ sorted: [sorted, setSorted] }} values={values} />
            <Search query={query} handleQuery={handleQuery}>
              Search List:
            </Search>
          </div>
          <div className="school-list">
            <ul>
              <List query={query} schools={mapped} />
            </ul>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div className="columns section-billboard">
        <div className="column is-6 p-0">
          <div>
            <SchoolMap height="300px" />
          </div>
        </div>
        <div className="column is-3 p-0 ml-6">
          <div>
            <div className="schools-key">
              <div className="schools-key-item">
                <div className="active"></div>
                <div>SCHOOLS WITH LECTURES</div>
              </div>
              <div className="schools-key-item">
                <div className="inactive"></div>
                <div>SCHOOLS WITHOUT LECTURES</div>
              </div>
            </div>
          </div>
          <div className="schools-stats">
            <SchoolStats />
          </div>
        </div>
        <div className="column is-2 ml-6 p-0">
          <p>
            Only schools with lectures are currently active. As data and general
            information regarding each school is uploaded it will become active.
          </p>
        </div>
      </div>

      {/*   */}
      <div className="columns">
        <div className="column is-12 p-0">
          <SchoolListing />
        </div>
      </div>
      <ScrollToTop speed={100} />
    </>
  )
}
