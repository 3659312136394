import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'
//import {SpeakerList} from '../features/speaker/SpeakerList'
import { BreadcrumbItem } from '../components/navigator/subNav'

import ArticlesHome from './ArticlesHome'
import ArticlesDetail from './ArticlesDetail'

export default function Articles() {
  let { path } = useRouteMatch()

  return (
    <section>
      <div className="columns toolbar-section">
        <nav className="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <Switch>
              <Route exact path={path}>
                <li className="is-active">Articles</li>
              </Route>
              <Route path={`${path}/:slug`}>
                <li>
                  <Link to="/articles">Articles</Link>
                </li>

                <BreadcrumbItem />
              </Route>
            </Switch>
          </ul>
        </nav>
      </div>
      <Switch>
        <Route exact path={path}>
          <ArticlesHome />
        </Route>
        <Route path={`${path}/:slug`}>
          <div><ArticlesDetail id="" /></div>
        </Route>
      </Switch>
    </section>
  )
}
