import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import loading_img from '../../assets/load_blink_2.gif'
import { fetcher } from '../../scripts/net'
import useSWR from 'swr'

const LectureList = (props) => {
  // Url for lecture list
  const url = props.url

  const useLectures = () => {
    const { data, error } = useSWR(url, fetcher)
    return {
      lecture: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  // Value Sort
  const valueSort = (list, sortValue, dir) => {
    let sorted = list.sort((a, b) => {
      if (a[sortValue] < b[sortValue]) return -1
      if (a[sortValue] > b[sortValue]) return 1
      return 0
    })
    return !dir ? sorted : sorted.reverse()
  }

  // List Component
  const List = ({ listing }) => {
    let lecList = listing.map((l, i) => (
      <tr key={i}>
        <td>{l.views}</td>
        <td>{l.date_show}</td>
        <td>
          <Link to={`/schools/${l.host_slug}`}>{l.host_name}</Link>
        </td>
        <td>
          <Link to={`/lectures/${l.id}`}>{l.title}</Link>
        </td>

        <td>
          {l.guests.map((g, j) => (
            <div key={j}>
              <Link to={`/people/${g.slug}`}>{g.name}</Link>
            </div>
          ))}
        </td>
      </tr>
    ))

    return <>{lecList}</>
  }

  // NavBar Component
  const NavBar = (props) => {
    const {
      sorted: [sorted, setSorted],
    } = { sorted: useState(), ...props.sorted }

    return (
      <nav>
        <ul>
          {props.values.map((value, i) => (
            <li
              className={sorted.index !== i ? 'is-off' : 'is-active'}
              key={i}
              onMouseDown={() =>
                setSorted({
                  ...sorted,
                  index: i,
                  sort: value.sort,
                  dir: sorted.dir ? 0 : 1,
                })
              }
            >
              {value.title}
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  // Primary Listing Component
  function LectureListing() {
    let values = [
      { sort: 'date', title: 'Date' },
      { sort: 'views', title: 'Views' },
      { sort: 'host_name', title: 'Host' },
    ]

    // const [query, setQuery] = useState('')
    const [sorted, setSorted] = useState({
      index: 0,
      sort: '',
      dir: 0,
    })

    const { lecture, isLoading, isError } = useLectures()
    if (isLoading)
      return (
        <div>
          <img src={loading_img} alt="LOADING" />
        </div>
      )
    if (isError) return <div>ERROR</div>

    let vals = values.map((value) => value.sort)
    let mapped = vals.includes(sorted.sort)
      ? valueSort([...lecture.lectures], sorted.sort, sorted.dir)
      : valueSort([...lecture.lectures], values[0], sorted.dir)

    return (
      <div>
        <div className="listing-navbar">
          <NavBar sorted={{ sorted: [sorted, setSorted] }} values={values} />
        </div>
        <div>
          <table className="table lecture">
            <thead>
              <tr>
                <th>Views</th>
                <th>Date</th>
                <th>Host</th>
                <th>Title</th>
                <th>Guests</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>Views</th>
                <th>Date</th>
                <th>Host</th>
                <th>Title</th>
                <th>Guests</th>
              </tr>
            </tfoot>
            <tbody>
              <List listing={mapped} />
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  return (
    <>
      <LectureListing />
    </>
  )
}

export { LectureList }
