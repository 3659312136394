import React from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';


const ToolbarSub = () => {
    const { slug }: any = useParams()
    let clean_slug = slug.replaceAll("-", " ")
return (<li className="is-capitalized is-active"><a href="#" aria-current="page">{clean_slug}</a></li>)
}

// function CleanedItem() {
//     const { slug }: any = useParams()
//     let clean_slug = slug.replaceAll("-", " ")
//     return clean_slug
// }
const BreadcrumbItem = () => {
    const { slug }: any = useParams()
    let clean_slug = slug.replaceAll("-", " ")
return (<li className="is-capitalized is-active"><a href="#" aria-current="page">{clean_slug}</a></li>)
}



export { BreadcrumbItem, ToolbarSub }