/* eslint-disable jsx-a11y/anchor-has-content */

import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import loading_img from '../assets/load_blink_2.gif'
import axios from 'axios'
// import scroll_to_top from '../scripts/ui'
import { Input } from '@mantine/core'
import { IconAt } from '@tabler/icons'

import { MapItem } from '../components/media/mapContent'

import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import L, { LatLng } from 'leaflet'
import { useLeafletContext } from '@react-leaflet/core'
import { cleanup } from '@testing-library/react'
import { ScrollToTop } from '../scripts/ui'
import { VideoScreen } from '../components/media/videoContent'
import useSWR from 'swr'
import { fetcher } from '../scripts/net'

export default function SchoolsDetail() {
  // Props & Params & State & Store
  const { slug } = useParams()
  const loc = `${process.env.REACT_APP_API_ROUTE}/schools/${slug}/` // School Detail

  const [show, setShow] = useState({ status: 'CLOSED', guests: [] }) //
  const [school, setSchool] = useState()

  const url = `${process.env.REACT_APP_API_ROUTE}/schools/${slug}/guests/`
  const { data, isError, isLoading } = useSWR(url, fetcher)

  const [speakers, setSpeakers] = useState()

  useEffect(() => {
    if (isLoading) return <img src={loading_img} alt="LOADING" />
    if (isError) return <div>ERROR</div>

    if (!data) return null
    const speakers = (
      <ul>
        {data.map((speaker, i) => (
          <li key={i}>
            {speaker.name} - {speaker.appearances}
          </li>
        ))}
      </ul>
    )
    setSpeakers(speakers)
  }, [data, isError, isLoading])

  // API Call
  useEffect(() => {
    axios
      .get(loc)
      .then((res) => {
        setSchool(res.data)
      })
      .catch((e) => {
        Error(e)
      })
  }, [loc])

  const stats_loc = `${process.env.REACT_APP_API_ROUTE}/schools/${slug}/stats/`
  const [schoolStats, setSchoolStats] = useState(undefined)
  useEffect(() => {
    axios
      .get(stats_loc)
      .then((res) => {
        setSchoolStats(res.data)
      })
      .catch((e) => {
        Error(e)
      })
  }, [stats_loc])

  const [nearby, setNearby] = useState(null)
  const [stats, setStats] = useState(null)
  const [map, setMap] = useState(null)
  useEffect(() => {
    if (schoolStats !== null && schoolStats?.location) {
      setStats(
        <div>
          LONGITUDE {schoolStats.location.lon} - LATITUDES
          {schoolStats.location.lat}
        </div>
      )

      setNearby(
        schoolStats.schools.map((school, i) => (
          <div key={i}>
            {school.name}, {school.lecture_count}, {school.distance}
          </div>
        ))
      )

      const mapItem = setInterval(() => {
        setMap(
          <MapItem
            center={{
              lat: schoolStats.location.lat,
              lng: schoolStats.location.lon,
            }}
            height="450px"
            width="100%"
            zoom={13}
          />
        )
      }, 1000)

      return () => clearInterval(mapItem)
    }
  }, [schoolStats])

  const lecture_loc = `${process.env.REACT_APP_API_ROUTE}/schools/${slug}/lectures/` // School Lectures
  const [schoolLectures, setSchoolLectures] = useState(null)
  // API Call
  useEffect(() => {
    axios
      .get(lecture_loc)
      .then((res) => {
        setSchoolLectures(res.data)
      })
      .catch((e) => {
        Error(e)
      })
  }, [lecture_loc])

  let lectures
  let host_name
  let route

  const [media, setMedia] = useState({
    code: <div className="hide"></div>,
    status: false,
    url: '',
  })

  const toggleContent = (props) => {
    let curStatus = media.status
    if (!media.status) {
      setMedia({
        code: (
          <div className="show">
            <VideoScreen
              title={props.title}
              embed_url={props.embed_url}
              ratio="ar53"
            ></VideoScreen>
          </div>
        ),
        status: true,
        url: props.embed_url,
      })
    } else {
      if (props.embed_url !== media.url) {
        setMedia({
          code: <div className="hide"></div>,
          // code: <div className="hide">VIDEO B {media.url} FADEOUT</div>,
        })
        media.code = <div>LOADING</div>
        console.log(media.code)
        setTimeout(() => {
          setMedia({
            code: (
              <div className="show">
                <VideoScreen
                  title={props.title}
                  embed_url={props.embed_url}
                  ratio="ar53"
                ></VideoScreen>
              </div>
            ),
            status: true,
            url: props.embed_url,
          })
        }, 50)
        clearTimeout()
      }
    }
    return curStatus
  }

  if (school !== null && school?.name) {
    if (schoolLectures !== null && schoolLectures?.lectures) {
      host_name = school.name
      lectures = schoolLectures.lectures.map((lecture, i) => (
        <li
          className="hover-cursor"
          key={i}
          onClick={() =>
            toggleContent({
              title: lecture.title,
              embed_url: lecture.embed_url,
            })
          }
          onMouseEnter={() =>
            setShow({
              status: lecture.source_url,
              guests: lecture.guests.map((lecture, i) => lecture.name),
            })
          }
          onMouseLeave={() => setShow({ status: 'CLOSED', guests: [] })}
          onMouseDown={() => ''}
        ></li>
      ))
    }
  }

  // let guests
  // if (show !== null && show?.guests) {
  //   guests = show.guests.map((guest, i) => <div key={i}>{guest}</div>)
  // }
  const [title, setTitle] = useState()
  useEffect(() => {
    if (slug !== null) {
      let clean_name = slug.replaceAll('-', ' ')
      setTitle(<div className="is-capitalized is-active">{clean_name}</div>)
      // return <div className="is-capitalized is-active">{clean_name}</div>
    }
  }, [slug])

  return (
    <>
      {route}
      <div className="section-billboard columns">
        <div className="column is-12 p-0">
          <h1 className="is-size-1">
            {title}
            {/* <Input icon={<IconAt />} placeholder="Your email" /> */}
          </h1>{' '}
          <div className="people-list">{speakers}</div>
        </div>
        <div className="column">{media.code}</div>
      </div>

      <div className="columns">
        <div className="column is-9">
          <div className="lecture">
            <div className="guest-container"></div>
            <div>{media.status}</div>
            {nearby}
            {stats}
          </div>
          <h4>Lectures</h4>
          <ul className="media-list">{lectures}</ul>
        </div>
      </div>

      <div className="columns">
        <div className="column is-12">{map}</div>
      </div>
      <ScrollToTop speed={100} />
    </>
  )
}
