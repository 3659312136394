import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import parse from 'html-react-parser'
import { fetchData } from '../../scripts/net'

import { Article } from '../../models/publication'
import logo_square from '../../assets/logo_square.png'
import { ToTitle } from '../../scripts/ui'
import { HashLink } from 'react-router-hash-link'

export default function SiteFooter(props) {
  // const id = props.id;
  // const {slug}: any = useParams()
  const sections = props.sections
  const pages = props.pages

  // const site_sections = sections.map((section, i) => (
  //   <li key={i}>
  //     <Link to={`/${section}`}>{ToTitle(section)}</Link>{' '}
  //   </li>
  // ))

  //   <HashLink smooth to={`/links#${link.slug}`}>
  //   {link.title}
  // </HashLink>

  const site_sections = sections.map((section, i) =>
    section === 'links' ? (
      <li key={i}>
        <HashLink smooth to={`/${section}#top`}>
          {ToTitle(section)}
        </HashLink>{' '}
      </li>
    ) : section === 'people' ? (
      <li key={i}>
        <HashLink smooth to={`/${section}#top`}>
          {ToTitle(section)}
        </HashLink>{' '}
      </li>
    ) : (
      <li key={i}>
        <Link to={`/${section}`}>{ToTitle(section)}</Link>{' '}
      </li>
    )
  )

  const site_pages = pages.map((page, i) => (
    <li key={i}>
      <Link to={`/${page}`}>{ToTitle(page)}</Link>{' '}
    </li>
  ))

  return (
    <footer className="site-footer">
      <div className="container">
        <div className="columns">
          <div className="column is-3">
            <div className="columns">
              <div className="column is-9">
                <img src={logo_square} />
                <div>Sub Content</div>
              </div>
              <div className="column is-3"></div>
            </div>
          </div>
          <div className="column is 9">
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column is-3">
                <ul>{site_sections}</ul>
              </div>
              <div className="column is-3">
                <ul>{site_sections}</ul>
              </div>
              <div className="column is-3">
                <ul>{site_pages}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
