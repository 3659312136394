import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ScrollToTop(props: { speed: number; tag: string }) {
  const speed = props.speed
  const tag = !props.tag ? '#root' : props.tag
  const { pathname } = useLocation()

  useEffect(() => {
    setTimeout(() => {
      const body = document.querySelector(tag)
      if (body) {
        body.scrollIntoView({ behavior: 'smooth' })
      }
    }, speed)
  }, [pathname, speed, tag])

  return null
}

const ToTitle = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export { ToTitle, ScrollToTop }
