import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

export default function Projects() {
  // The `path` lets us build <Route> paths that are

  return (
    <div>
      <h1>Projects</h1>
    </div>
  );
}
