import React from 'react'
import { Link, NavLink } from 'react-router-dom'

interface Sections {
  sections: string[]
}

const ToolbarMain = (props: Sections) => {
  const cur_sections = props.sections
  let nav_items = cur_sections.map((section, i) => (
    <li key={i}>
      <NavLink activeClassName="is-active" to={`/${section.toLowerCase()}`}>
        <div className="is-capitalized">{section}</div>
      </NavLink>
    </li>
  ))

  return (
    <section className="top-nav--wrap">
      <header className="container top-nav--header">
        <nav className="top-nav--nav columns is-mobile">
          <div className="column is-12">
            <div id="brand-logo" className="level-item has-text-centered">
              <Link to="/">
                <div id="brand-img"></div>
              </Link>
            </div>
            <div className="top-nav--mobile">
              <ul className="mobile-nav-items">{nav_items}</ul>
            </div>
            <div>
              <ul className="navbar-end">{nav_items}</ul>
            </div>
          </div>
        </nav>
      </header>
    </section>
  )
}

export { ToolbarMain }
