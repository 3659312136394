import { useEffect, useState } from 'react'
import axios from 'axios'
import { VideoScreen } from './videoContent'

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Circle,
  CircleMarker,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

// function MyComponent() {
//   const map = useMap()
//   console.log('map center:', map.getCenter())
//   return null
// }

const MapItem = (props: any) => {
  const height = props.height
  const width = props.width
  const zoom = props.zoom
  const center = props.center

  // function ChangeView({ center }) {
  //   const map = useMap()
  //   map.flyTo(center)
  //   return null
  // }

  const [schoolmap, setSchoolMap] = useState(undefined)
  useEffect(() => {
    setSchoolMap(
      <MapContainer
        center={center}
        style={{ height: height, width: width }}
        zoom={zoom}
      >
        {/* <ChangeView center={center} /> */}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <CircleMarker center={center} radius={20}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </CircleMarker>
      </MapContainer>
    )
  }, [center, height, width, zoom])

  return <div>{schoolmap}</div>
}
export { MapItem }
