import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import parse from "html-react-parser"
import { fetchData } from "../scripts/net";



export default function ArticlesDetail(props) {
    const id = props.id;
    const {slug} = useParams()


    // http://127.0.0.1:8000/api/articles/slug/house-prices-continue-to-go-through-the-roof/

    const loc = `${process.env.REACT_APP_API_ROUTE}/articles/slug/${slug}/`

  
    // const [person, setPerson] = useState<Person>()
    const [article, setArticle] = useState()
  
    useEffect(() => {
      let isCancelled = false
      fetchData(loc)
        .then((data) => data && setArticle(data))
        .catch((e) => {
          Error(e)
        })
      if (!isCancelled) {
        return () => {
          isCancelled = true
        }
      }
    }, [loc])
    let title, body
    if (article !== null && article?.title) {
     title = article.title
     body = article.body
     body = parse(body)
    }
    

  return <>DETAIL PAGE - {slug} - {id} {title} {body}</>
}
