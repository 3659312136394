import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'
//import {SpeakerList} from '../features/speaker/SpeakerList'
import { BreadcrumbItem } from '../components/navigator/subNav'

import PeopleDetail from './PeopleDetail'
import PeopleHome from './PeopleHome'

export default function People() {
  let { path } = useRouteMatch()

  return (
    <section>
      <div className="columns toolbar-section">
        <nav className="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <Switch>
              <Route exact path={path}>
                <li className="is-active">
                  <h1>People</h1>
                </li>
              </Route>
              <Route path={`${path}/:slug`}>
                <li>
                  <Link to="/people">
                    <h1>People</h1>
                  </Link>
                </li>

                <BreadcrumbItem />
              </Route>
            </Switch>
          </ul>
        </nav>
      </div>

      <div className="page-body">
        <Switch>
          <Route exact path={path}>
            <div className="column is-12 is-gapless p-0">
              <PeopleHome />
            </div>
          </Route>
          <Route path={`${path}/:slug`}>
            <div className="column is-12 is-gapless p-0">
              <PeopleDetail />
            </div>
          </Route>
        </Switch>
      </div>
    </section>
  )
}
