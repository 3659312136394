import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import parse from 'html-react-parser'
import { VideoScreen } from '../components/media/videoContent'

export default function ArticlesHome() {
  const content = (
    <>
      {/* <section className="layout-section page">
        <div className="columns layout-sect is-gapless B1">
          <div className="column is-6">
            <div className="layout-img ar43">ssds</div>
            <div className="content">
              <h4>This is a title for an article</h4>
              <p>
                This is a sbhort descruotihjd dsjskd ftge dkjs tiryd rgw ainew
                owiokw kljke to think that they had.
              </p>
            </div>
          </div>
          <div className="column is-6">
            <div className="B2">
              <div className="B-header">SubB Header</div>
              <div className="columns">
                <div className="column left">
                  SubB left
                  <ul className="block">
                    <li>One</li>
                    <li>Two</li>
                    <li>Three</li>
                    <li>Four</li>
                  </ul>
                </div>
                <div className="column right">
                  SubB right
                  <ul>
                    <li>One</li>
                    <li>Two</li>
                    <li>Three</li>
                    <li>Four</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="layout-section page">
        <div className="columns layout-sect B1">
          <div className="column">
            <aside>
              <div className="content layout-img ar11">IMG</div>
              <h4 className="title">This is a title</h4>
              <p className="content">
                This is a short description in support of the title.
              </p>
            </aside>
          </div>
          <div className="column">
            <aside>
              <div className="content layout-img ar11">IMG</div>
              <h4 className="title">This is a title</h4>
              <p className="content">
                This is a short description in support of the title.
              </p>
            </aside>
          </div>
          <div className="column">
            <aside>
              <div className="content layout-img ar11">IMG</div>
              <h4 className="title">This is a title</h4>
              <p className="content">
                This is a short description in support of the title.
              </p>
            </aside>
          </div>
          <div className="column">
            <aside>
              <div className="content layout-img ar11">IMG</div>
              <h4 className="title">This is a title</h4>
              <p className="content">
                This is a short description in support of the title.
              </p>
            </aside>
          </div>
        </div>
      </section> */}

      <section className="layout-section page">
        <div className="columns layout-sect">
          <div className="column is-2 pt-0 pl-0">
            <aside className="module">
              <div className="el">
                <div className="content layout-img-wrapper">
                  <div className="layout-img ar32"></div>
                  <div className="credit content is-small">
                    by Juan Garcia Cortez
                  </div>
                </div>
                <h4 className="content">The 16 Best TV Shows of 2021</h4>
                <h3 className="content is-small">
                  We’ve broken down the chances of transmission at a gathering
                  with one unvaccinated adult.
                </h3>
              </div>
            </aside>

            <aside className="module">
              <div className="el">
                <div className="content layout-img-wrapper">
                  <div className="layout-img ar32"></div>
                  <div className="credit content is-small">
                    David Dolsen / Comedy Central
                  </div>
                </div>
                <h4 className="content">The 16 Best TV Shows of 2021</h4>
                <h3 className="content is-small">
                  We’ve broken down the chances of transmission at a gathering
                  with one unvaccinated adult.
                </h3>
              </div>
            </aside>
          </div>
          <div className="column is-7 pt-0">
            <article className="layout-sect">
              <div className="layout-img-wrapper">
                <div className="layout-img ar43 B1">VIDEO</div>
              </div>

              <div className="content">
                <p>
                  <strong>John Smith</strong> <small>@johnsmith</small>{' '}
                  <small>31m</small>
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  ornare magna eros, eu pellentesque tortor vestibulum ut.
                </p>
              </div>
            </article>
          </div>
          <div className="column is-3 B1 pr-0 pt-0">
            <article className="layout-sect-item media">
              <p className="content is-small">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ornare magna eros, eu pellentesque tortor vestibulum ut.
              </p>

              <div className="layout-img-wrapper media-right">
                <div className="layout-img ar11"></div>
              </div>
            </article>
            <article className="layout-sect-item media">
              <p className="content is-small">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ornare magna eros, eu pellentesque tortor vestibulum ut.
              </p>

              <div className="layout-img-wrapper media-right">
                <div className="layout-img ar11"></div>
              </div>
            </article>
            <article className="layout-sect-item media">
              <p className="content is-small">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ornare magna eros, eu pellentesque tortor vestibulum ut.
              </p>

              <div className="layout-img-wrapper media-right">
                <div className="layout-img ar11"></div>
              </div>
            </article>
            <article className="layout-sect-item media">
              <p className="content is-small">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ornare magna eros, eu pellentesque tortor vestibulum ut.
              </p>

              <div className="layout-img-wrapper media-right">
                <div className="layout-img ar11"></div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  )

  return content
}
