import axios from 'axios'

async function fetchData(url: string) {
    return fetch(url)
      .then(response => response.json())
  
  }

const fetcher = (url) => axios.get(url).then((res) => res.data)

export {fetcher, fetchData}