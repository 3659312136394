import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'

import { BreadcrumbItem } from '../components/navigator/subNav'
import SchoolsHome from './SchoolsHome'
import SchoolsDetail from './SchoolsDetail'

//SCHOOLS
export default function Schools() {
  let { path } = useRouteMatch()

  return (
    <>
      <section>
        <div className="columns toolbar-section">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <Switch>
                <Route exact path={path}>
                  <li className="is-active">Schools</li>
                </Route>
                <Route path={`${path}/:slug`}>
                  <li>
                    <Link to="/schools">Schools</Link>
                  </li>

                  <BreadcrumbItem />
                </Route>
              </Switch>
            </ul>
          </nav>
        </div>

        <Switch>
          <Route exact path={path}>
            <SchoolsHome />
          </Route>
          <Route path={`${path}/:slug`} children={<SchoolsDetail />} />
        </Switch>
      </section>
    </>
  )
}
