import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

interface SpeakersState {
  people: string[]
}

const initialState: SpeakersState = {
  people: [],
}

export const speakersSlice = createSlice({
  name: 'speakers',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    insertSpeaker: (state, action: PayloadAction<string>) => {
      let spkr: string = action.payload
      let people = state.people.concat(spkr)
      let unique = [...new Set(people)]
      state.people = unique
      console.log(state.people)
    },
  },
})

export const { insertSpeaker } = speakersSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSpeakers = (state: RootState) => state.speakers.people

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default speakersSlice.reducer
