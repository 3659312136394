import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import parse from 'html-react-parser'
import useSWR from 'swr'
import { fetcher } from '../scripts/net'

import { VideoScreen } from '../components/media/videoContent'

export default function LinksHome() {
  const useLinks = () => {
    let url = `${process.env.REACT_APP_API_ROUTE}/home/links/`
    const { data, error } = useSWR(url, fetcher)
    return {
      links: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  function LinkListing() {
    const { links, isLoading, isError } = useLinks()
    if (isLoading)
      return <div>{/* <img src={loading_img} alt="LOADING" /> */}</div>
    if (isError) return <div>ERROR</div>
    const link_list = links.map((link, i) => (
      <>
        <div className="links-item" id={link.slug}>
          <div className="is-size-4">
            <a href={link.url} target="_blank">
              {link.title}
            </a>
            <div className="links-content">{parse(link.body)}</div>
            <div className="links-ref">
              {link.date} | <a href={link.host_url}>{link.host}</a>
            </div>
          </div>
        </div>
      </>
    ))
    return link_list
  }

  const content = (
    <>
      <section className="layout-section page">
        <div className="columns">
          <div className="column is-2 pt-0 pl-0"></div>
          <div className="column is-7 pt-0">
            <div className="links-list">
              {/* <div className="layout-img-wrapper">
                <div className="layout-img ar43 B1">VIDEO</div>
              </div> */}
              <LinkListing />
            </div>
          </div>
          <div className="column is-3 B1 pr-0 pt-0"></div>
        </div>
      </section>
    </>
  )

  return content
}
