import { useEffect, useState } from 'react'

import './App.sass'
import { ToolbarMain } from './components/navigator/mainNav'
import { Switch, Route, useParams } from 'react-router-dom'

import Home from './pages/SiteHome'

import SiteFooter from './components/layout/SiteFooter'
import People from './pages/People'
import Schools from './pages/Schools'
import Projects from './pages/Projects'
import Lectures from './pages/Lectures'
import Articles from './pages/Articles'
import Links from './pages/Links'
import Helmet from 'react-helmet'
import axios from 'axios'
import useSWR from 'swr'
import { fetcher } from './scripts/net'
import { ScrollToTop } from './scripts/ui'
import parse from 'html-react-parser'
import loading_img from './assets/load_blink_2.gif'
// import { Nav } from './features/nav/Nav';
// import { Panels } from './features/nav/Panels';
// const sections = ['articles', 'people', 'schools', 'lectures']

// let section_list = sections.map((section, i) => <div>{section}</div>)
const NoMatch = () => {
  return <div>Sorry Ain't Nothing Here</div>
}

function App() {
  const usePage = (slug) => {
    const url = `${process.env.REACT_APP_API_ROUTE}/site/pages/${slug}/`
    const { data, error } = useSWR(url, fetcher)
    return {
      content: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  function PageMatch() {
    const { page } = useParams()

    const PageContent = () => {
      const { content, isLoading, isError } = usePage(page)
      if (isLoading)
        return (
          <div>
            <img src={loading_img} alt="LOADING" />
          </div>
        )
      if (isError) return <div>ERROR</div>
      let page_content = parse(content.content)
      return (
        <div>
          <div>{page_content}</div>
        </div>
      )
    }

    if (page !== null && pages.includes(page)) {
      return (
        <div>
          {page}
          <PageContent />
          <ScrollToTop speed={100} />
        </div>
      )
    } else if (!page) {
      return (
        <div>
          Oops! Nothing Here <ScrollToTop speed={100} />{' '}
        </div>
      )
    }
    ;<ScrollToTop speed={100} />
    return page
  }

  const url = `${process.env.REACT_APP_API_ROUTE}/site/data/`
  const [data, setData] = useState({})
  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setData(res.data)
      })
      .catch((e) => {
        Error(e)
      })
  }, [url])

  const [sections, setSections] = useState([])
  useEffect(() => {
    if (data !== null && data?.sections) {
      setSections(data.sections)
    }
  }, [data])

  const [pages, setPages] = useState([])
  useEffect(() => {
    if (data !== null && data?.pages) {
      setPages(data.pages)
    }
  }, [data])

  return (
    <>
      <Helmet>
        <title>ARCHFNDR</title>
      </Helmet>

      <ToolbarMain sections={sections} />
      <section className="content-main section">
        {/* <div className='columns'><div class=''></div></div> */}
        <div className="container">
          <div className="columns is-gapless">
            <div className="column is-12-mobile is-12-tablet is-12-desktop">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/articles" component={Articles} />
                <Route path="/links" component={Links} />
                <Route path="/people" component={People} />
                <Route path="/schools" component={Schools} />
                <Route path="/lectures" component={Lectures} />
                <Route path="/projects" component={Projects} />
                <Route path="/:page" children={<PageMatch />} />
                <Route path="*" component={NoMatch}></Route>
              </Switch>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              SUB{' '}
              <div
                onClick={() =>
                  document
                    .querySelector('#root')
                    .scrollIntoView({ behavior: 'smooth' })
                }
              >
                Go Up
              </div>
            </div>
          </div>
        </div>
      </section>
      <SiteFooter sections={sections} pages={pages} />
    </>
  )
}

export default App
