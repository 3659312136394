import { useEffect, useState } from 'react'

const VideoScreen = (props: any) => {
  const title = props.title
  const url = props.embed_url
  const desc = props.desc
  const ratio = props.ratio

  let lec_ratio: string

  if (ratio !== undefined) {
    lec_ratio = ratio
  } else {
    lec_ratio = 'ar21'
  }

  const [vid, setVid] = useState<any | null>(null)
  let https_url = url.substring(0, 4) + 's' + url.substring(4)
  useEffect(() => {
    if (url !== null) {
      if (url.includes('youtube')) {
        setVid(
          <iframe
            className="video"
            src={https_url}
            title={title}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        )
      } else if (url.includes('vimeo')) {
        setVid(
          <>
            <iframe
              className="video"
              src={https_url}
              frameBorder="0"
              title={title}
              allow="fullscreen"
            ></iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </>
        )
      } else if (url.includes('.mp4')) {
        setVid(
          <>
            <video controls>
              <source src={https_url} type="video/mp4" />
              Your browser does not support the content
            </video>
          </>
        )
      } else {
        setVid(<div>Error Playing Video</div>)
      }
    }
  }, [https_url, url, title])

  return (
    <>
      <div className={'layout-img ' + lec_ratio}>
        <figure className={'video-embed ' + lec_ratio}>{vid}</figure>
      </div>
    </>
  )
}

export { VideoScreen }
