import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'
import { BreadcrumbItem } from '../components/navigator/subNav'
import SchoolsHome from './SchoolsHome'
import SchoolsDetail from './SchoolsDetail'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { fetcher } from '../scripts/net'
import { ScrollToTop } from '../scripts/ui'
import { VideoScreen } from '../components/media/videoContent'
//LECTURES

export default function LecturesDetail() {
  const { id } = useParams()

  const useLecture = () => {
    const url = `${process.env.REACT_APP_API_ROUTE}/lectures/${id}/`
    const { data, error } = useSWR(url, fetcher)
    return {
      lecture: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  function LectureContent() {
    const { lecture, isLoading, isError } = useLecture()
    if (isLoading) return <div>Loading</div>
    if (isError) return <div>ERROR</div>
    let guests = lecture.guests.map((guest, i) => (
      <div key={i}>
        {guest.name} - {guest.slug}
      </div>
    ))
    return (
      <>
        <div>
          <div id="brand-loading" className="sml billboard">
            <VideoScreen
              title={lecture.title}
              embed_url={lecture.embed_url}
              ratio="ar53"
            ></VideoScreen>
          </div>
          <div className="lecture-info">{lecture.title}</div>
          <div className="lecture-info">{lecture.date}</div>
          <div className="lecture-info">{lecture.created}</div>
          <div className="lecture-info">{lecture.source_url}</div>
          <div className="lecture-info">
            <Link to={`/schools/${lecture.host_slug}`}>
              {lecture.host_name}
            </Link>
          </div>
          {guests}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column is-7">
          <LectureContent />
        </div>
        <div className="column is-3"></div>
      </div>
      <ScrollToTop speed={100} />
    </>
  )
}
