import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from '../../scripts/net';

import { DonutChart } from '@carbon/charts-react';



function PersonHost (props) {
  const person_id = props.id;
  const host_loc = `${process.env.REACT_APP_API_ROUTE}/people/${person_id}/hosts/`;

  // Nested empty object is needed for useState before population. Give useState a default that matches
  // the request object. Ex. [] for list, {a:0} for k:v, 0 for int, '' for string.
  // https://stackoverflow.com/questions/65833137/cant-access-nested-json-with-react-hooks-typeerrors
  const [hosts, setHosts] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (person_id !== undefined) {
      fetchData(host_loc)
        .then(setHosts)
        .catch((e) => {
          Error(e);
        });
      if (!isCancelled) {
        return () => {
          isCancelled = true;
        };
      }
    }
  }, [person_id, host_loc]);



  const hostStatus = !Array.isArray(hosts) && hosts.hosts ;

  let hostList;
  if (hostStatus) {
    hostList = hosts.hosts.map((host) => (
      <li key={host.name}>
        <Link to={`/schools/${host.slug}`} >{host.name} </Link>::{host.appearances} {host.location.lat} - {host.location.lng}
      </li>
    ));
  }

  const options = {
    title: '',
    resizable: false,
    legend: { enabled: false },
    tooltip: {},
    //   alignment: 'center',
    //   position: 'left',
    // },
    donut: {
      center: {
        label: 'Lectures',
      },
      alignment: 'center',
    },
    height: '200px',
  };

  let hostGraph = [];
  if (hostStatus) {
    hosts.hosts.map((host) =>
      hostGraph.push({ group: host.shortname, value: host.appearances })
    );
  }

  return (
    <ul id='people-detail-stats'>
      <li>

      </li>
      <li>
        <ul>{hostList}</ul>
      </li>
      <li>
        <div>
          <DonutChart data={hostGraph} options={options}></DonutChart>
        </div>
      </li>
    </ul>
  );
};
export default PersonHost;
