import { useEffect, useState } from 'react'
import axios from 'axios'
import { VideoScreen } from './videoContent'

interface Guest {
  name: string
  slug: string
}

interface Lecture {
  title: string
  views: number
  desc: string
  date_sort: string
  date_show: string
  host: string
  host_id: number
  host_slug: string
  embed_url: string
  source_url: string
  created: string
  guests: Guest[]
}

interface LectureData {
  count: number
  lectures: Lecture[]
}

const VideoStrip = (props: any) => {
  const url = props.url
  const li_class = props.li_class
  const ul_class = props.ul_class
  const ratio = props.ratio

  const [data, setData] = useState(undefined)
  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setData(res.data)
      })
      .catch((e) => {
        Error(e)
      })
  }, [url])

  const [StripItems, setStripItems] = useState(undefined)
  useEffect(() => {
    if (data !== null && data?.lectures) {
      let items = data.lectures.map((lecture, i) => (
        <li key={i} className={`${li_class}`}>
          <VideoScreen
            embed_url={lecture.embed_url}
            title={lecture.title}
            desc={lecture.desc}
            ratio={ratio}
          />
        </li>
      ))
      setStripItems(items)
    }
  }, [data, li_class, ratio])

  return <ul className={`${ul_class}`}>{StripItems}</ul>
}
export { VideoStrip }
