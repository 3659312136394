import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'
import { BreadcrumbItem } from '../components/navigator/subNav'
import LecturesHome from './LecturesHome'
import LecturesDetail from './LecturesDetail'
//LECTURES

export default function Lectures() {
  let { path } = useRouteMatch()

  return (
    <>
      <section className="section p-0">
        <div className="columns toolbar-section">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <Switch>
                <Route exact path={path}>
                  <li className="is-active">Lectures</li>
                </Route>
                <Route path={`${path}/:id`}>
                  <li>
                    <Link to="/lectures">Lectures</Link>
                  </li>
                  {/* 
                <BreadcrumbItem /> */}
                </Route>
              </Switch>
            </ul>
          </nav>
        </div>

        <Switch>
          <Route exact path={path}>
            <LecturesHome />
          </Route>
          <Route path={`${path}/:id`}>
            <LecturesDetail />
          </Route>
        </Switch>
      </section>
    </>
  )
}
