import { useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link'

import axios from 'axios'
import useSWR from 'swr'

import { VideoScreen } from '../components/media/videoContent'
import { VideoStrip } from '../components/media/videoStrip'
import loading_img from '../assets/load_blink_2.gif'

const fetcher = (url) => axios.get(url).then((res) => res.data)

export default function Home() {
  const [lecture, setLecture] = useState()

  const useLinks = () => {
    let url = `${process.env.REACT_APP_API_ROUTE}/home/links/?limit=8`
    const { data, error } = useSWR(url, fetcher)
    return {
      links: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  function LinkListing() {
    const { links, isLoading, isError } = useLinks()
    if (isLoading)
      return (
        <div>
          <img src={loading_img} alt="LOADING" />
        </div>
      )
    if (isError) return <div>ERROR</div>
    const link_list = links.map((link, i) => (
      <div className="snippet" key={i}>
        <div className="columns mb-1">
          <div className="column is-12 is-size-6 p-0">
            <HashLink smooth to={`/links#${link.slug}`}>
              {link.title}
            </HashLink>
          </div>
        </div>
        <div className="columns">
          <div className="column is-4 is-size-7 p-0">{link.date}</div>
          <div className="column is-8 is-size-7 p-0 pl-2">
            <a href={link.host_url} target="_blank">
              {link.host}
            </a>
          </div>
        </div>
      </div>
    ))
    return link_list
  }

  const useSpeaker = () => {
    let url = `${process.env.REACT_APP_API_ROUTE}/archfndr/featured/?f_type=person&d_type=W`
    const { data, error } = useSWR(url, fetcher)
    return {
      speaker: data,
      isLoading: !error && !data,
      isError: error,
    }
  }

  function FeaturedSpeaker() {
    const { speaker, isLoading, isError } = useSpeaker()
    if (isLoading) return <div id="brand-loading" className="xs"></div>
    if (isError) return <div>ERROR</div>
    return (
      // <div>{speaker.lectures.map((lecture, i) => lecture)}</div>
      <div className="snippet-video">
        <VideoScreen
          embed_url={speaker.lectures[0]}
          title={'TITLE'}
          desc={'This is a description of the lecture'}
          ratio={'ar53'}
        />
        <div> {speaker.title}</div>
      </div>
    )
  }

  let loc = `${process.env.REACT_APP_API_ROUTE}/archfndr/featured/?feature=lecture&type=W`
  useEffect(() => {
    axios
      .get(loc)
      .then((res) => {
        setLecture(res.data)
      })
      .catch((e) => {
        Error(e)
      })
  }, [loc])

  const [StripClass, setStripClass] = useState('hide')
  const [videoStrip, setVideoStrip] = useState(undefined)
  useEffect(() => {
    const fetch = async () => {
      const result = await (
        <VideoStrip
          ratio="4/3"
          ul_class=""
          li_class="column is-3"
          url={`${process.env.REACT_APP_API_ROUTE}/lectures/?limit=4&sort=views`}
        />
      )
      setVideoStrip(result)
      setInterval(() => setStripClass('show'), 1000)
    }
    fetch()
  }, [])

  let lec
  if (lecture !== null && lecture?.embed_url) {
    lec = (
      <>
        <h5 className="mb-5">Lecture of the Week</h5>
        <div className="card is-shadowless">
          <div className="card-image">
            <div id="brand-loading" className="sml billboard">
              <VideoScreen
                embed_url={lecture.embed_url}
                title={'TITLE'}
                desc={'This is a description of the lecture'}
                ratio={'ar53'}
              />
            </div>
          </div>

          <div className="card-content pl-0">
            <div className="content media-billboard-desc">
              ATLANTA (AP) — An Associated Press review of every potential case
              of voter fraud in has found fewassrrot number that would have made
              no filter bucket horse fraud in has found fewer than carro
              presidential election.
            </div>
            <ul className="topic-list">
              <li>Urban Planning</li>
              <li>Sustainability</li>
            </ul>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <section className="mb-6">
        <div className="columns toolbar-section">
          {/* <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li></li>
            </ul>
          </nav> */}
        </div>
        <div className="columns is-gapless">
          <div className="column is-2">
            <div className="columns is-gapless">
              <div className="column is-10">
                <h5 className="mb-5">In The Wild</h5>
                <LinkListing />
              </div>
              <div className="column is-2"></div>
            </div>
          </div>
          <div className="column is-7">{lec}</div>
          <div className="column is-3 ">
            <div className="columns is-gapless">
              <div className="column is-2"></div>
              <div className="column is-10">
                <h5 className="mb-5">Featured</h5>

                <div>
                  <FeaturedSpeaker />
                </div>
                <h5 className="mb-5">Featured Speaker</h5>

                <div className="ar43 snippet-video-media">
                  <FeaturedSpeaker />
                </div>
                <h5 className="mb-5">Random Lecture</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`section-block ${StripClass}`}>
        <h4>Popular Lectures</h4>
        <div className="columns">
          <VideoStrip
            ul_class="video-strip"
            li_class="column is-3"
            ratio="ar53"
            url={`${process.env.REACT_APP_API_ROUTE}/lectures/top/?limit=4`}
          />
        </div>
      </section>
      <section className="section-block">
        <h4>Latest Added</h4>
        <div className="columns">
          {' '}
          <VideoStrip
            ul_class="video-strip"
            li_class="column is-3"
            ratio="ar53"
            url={`${process.env.REACT_APP_API_ROUTE}/lectures/?dated=true&limit=4&views=10000&sort=views`}
          />
        </div>
      </section>
      <section className="section-block">
        <h4>Featured School</h4>
        <div className="columns">
          <VideoStrip
            ul_class="video-strip"
            li_class="column is-3"
            ratio="ar53"
            url={`${process.env.REACT_APP_API_ROUTE}/schools/columbia-university/lectures/?limit=4`}
          />
        </div>
      </section>
      <section className="section-block">
        <h4>From The Archives</h4>
        <div className="columns">
          <VideoStrip
            ul_class="video-strip"
            li_class="column is-3"
            ratio="ar53"
            url={`${process.env.REACT_APP_API_ROUTE}/lectures/random/?limit=4`}
          />
        </div>
      </section>
    </>
  )
}
